.CampusCardCheckbox {
    @mixin animatePropertyFaster box-shadow;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--whiteBaseColor);
    box-shadow: 0 0 0 1px var(--blackLighten40Color);
    overflow: hidden;

    @media (--tablet) {
        flex-direction: row;
    }

    &:hover {
        box-shadow: 0 0 0 1px var(--blackLighten20Color);
    }

    & .SubTitle,
    & .Description {
        @mixin p40;

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    & .Input,
    & .LabelInner {
        @mixin visuallyHidden;
    }
}

.Image {
    @mixin aspectRatio 2/3;
    flex: 0.45;
}

.Content {
    @mixin radio;
    position: initial;
    flex: 0.55;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin: 16px;

    &::before {
        background: var(--whiteBaseColor);
        top: 1em;
        left: 1em;
    }

    &::after {
        top: 1.4em;
        left: 1.4em;
    }

    @media (--tablet) {
        padding-right: 32px;
        position: relative;

        &.Content::before {
            top: 0.05em;
            right: 0;
            left: initial;
        }

        &.Content::after {
            top: 0.45em;
            right: 0.4em;
            left: initial;
        }
    }
}

.Title {
    @mixin h50;
    font-weight: 600;
    margin: 0;
}

.Label {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
}

.Input {
    @mixin onFocus {
        & + .Content::before {
            @mixin focusVisible 4px;
        }
    }
}

.Variant--selected {
    box-shadow: 0 0 0 2px var(--successLegacyColor);

    & .Input {
        &:checked {
            & + .Content::after {
                background: var(--blackBaseColor);
            }
        }
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}
