.StudyHero {
    @mixin heroBlockHeight;
    padding-bottom: 80px;

    @media (--belowDesktop) {
        &.StudyHero {
            min-height: initial;
            background: none;

            & .MediaSectionMedia {
                display: none;
            }
        }
    }

    @media (--desktop) {
        margin: calc(-1 * var(--headerHeight)) 0 0;
        padding: calc(40px + var(--headerHeight)) 0 80px;
    }

    & .LeftContent,
    & .RightContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    & .Breadcrumbs {
        margin: 24px 0;

        @media (--desktop) {
            --textColor: var(--whiteBaseColor);
            color: var(--textColor);
            margin: 0 0 24px;
        }
    }
}

.MediaSectionWrapper {
    & .MediaSectionContent {
        height: 100%;
    }

    &.MediaSectionWrapper {
        z-index: calc(var(--studyPageNavigationZIndex) + 1);
    }
}

.Content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Inner {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (--desktop) {
        @mixin grid;
        grid-template-columns: repeat(12, 1fr);
        height: 100%;
        color: var(--whiteBaseColor);

        & .LeftContent {
            grid-column: 1 / span 6;
        }

        & .RightContent {
            grid-column: var(--studyPageHeroContentColumnDesktop);
        }
    }

    @media (--desktop2K) {
        & .RightContent {
            grid-column: var(--studyPageHeroContentColumnDesktop2K);
        }
    }
}

.ImageWrapper {
    @mixin aspectRatio 9/16;
    margin: 0 calc(-1 * var(--wrapperHorizontalPadding)) 24px;

    @media (--desktop) {
        display: none;
    }
}

.Title {
    @mixin h30;
    @mixin hyphenateWords manual;
    margin-bottom: 24px;
    font-weight: 600;

    @media (--desktop) {
        @mixin h10;
    }

    @media (--desktopHD) {
        @mixin h00;
    }
}

.Summary {
    @mixin p10;

    @media (--tablet) {
        @mixin h50;
    }
}

.TagsWrapper {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    flex-wrap: wrap;
}

.TagLabel {
    @mixin p40;
    font-weight: 600;
}

.TagDuration {
    display: inline-block;
    margin-right: 4px;
}

.ChildrenWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
