.Picker {
    @mixin clearList;
}

.Pick {
    margin: 24px 0;
}

.Button {
    width: 100%;

    @media (--tablet) {
        width: auto;
    }
}

.Alert {
    @mixin collapseAnimation;
    margin: 24px 0;
}
