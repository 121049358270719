.AdmissionCallToAction {
    color: var(--blackBaseColor);
    background: var(--whiteBaseColor);
    padding: 24px;
}

.Content {
    @mixin p30;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &.Content--open {
        & .SubLabel {
            color: var(--notificationSuccessBaseColor);
        }
    }

    &.Content--closed {
        & .SubLabel {
            color: var(--notificationErrorBaseColor);
        }
    }

    &.Content--default {
        & .SubLabel {
            /* todo add default value styles */
        }
    }
}

.Label {
    margin-right: 8px;

    &::after {
        content: ':';
    }
}

.Action {
    width: 100%;
}

.Variant--shadowed {
    @mixin themeBoxShadowLarge;
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}
