.Breadcrumbs {
    @mixin label;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 16px;
    margin-bottom: 24px;
    padding: 0;
    width: 100%;

    @media (--tablet) {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    & li:not(:last-child)::after {
        content: '/';
        margin: 0 8px;
    }
}

.Link {
    @mixin onFocus {
        @mixin focusVisible;
    }
    border-bottom-width: 1px;
    border-bottom-color: var(--redDarken20LegacyColor);

    &:hover {
        border-bottom-width: 2px;
        border-bottom-color: var(--redLighten20LegacyColor);
    }
}
